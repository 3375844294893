import React, { useEffect, useState } from "react";
import "./callReports.css";
import { FaArrowLeft } from "react-icons/fa6";
import useProvideGeneralHooks from "../../../hooks/useProvideGeneralHooks";
import {
  customerType,
  customerPurpose,
  getInterval,
} from "../../../utils/contactSource";
import { Rating } from "@mui/material";
import { formatDistanceStrict } from "date-fns";

const CallReport = () => {
  const [report, setReport] = useState([]);
  const [selected, setSelected] = useState("interested");
  const { navigate } = useProvideGeneralHooks();
  useEffect(() => {
    const data = localStorage.getItem("report");
    const parsedData = JSON.parse(data);
    setReport(parsedData);
  }, []);

  const tabeHandler = (value) => {
    const data = localStorage.getItem("report");
    const prevContact = JSON.parse(data);
    setSelected(value);

    let filteredContacts;

    if (value.includes("interested")) {
      filteredContacts = prevContact.filter((item) => item.type.type === value);
    } else if (value.includes("not interested")) {
      filteredContacts = prevContact.filter((item) => item.type.type === value);
    } else if (value.includes("default")) {
      filteredContacts = prevContact.filter((item) => item.type.type === value);
    } else if (value.includes("buyer")) {
      filteredContacts = prevContact.filter((item) => item.purpose === value);
    } else if (value.includes("both")) {
      filteredContacts = prevContact.filter((item) => item.purpose === value);
    } else {
      filteredContacts = prevContact.filter((item) => item.purpose === value);
    }

    // Update the report state with the filtered result
    setReport(filteredContacts);
  };

  const backHandler = () => {
    localStorage.removeItem("report");
    navigate("/");
  };
  return (
    <div>
      <div className="call_detail_header">
        <FaArrowLeft onClick={backHandler} className="rounded" />
      </div>
      <div className="call_report_main">
        <div className="contact_types">
          {[...customerType, ...customerPurpose].map((contact, index) => {
            return (
              <span
                onClick={() => tabeHandler(contact)}
                className={contact === selected ? "filled" : "white"}
                key={index}
              >
                {contact}
              </span>
            );
          })}
        </div>

        <div className="call_reports_contacts">
          {report?.length > 0 ? (
            report.map((item, index) => {
              return (
                <div key={index} className="call_report_single_contact">
                  <div>
                    <span className="title">Contact Info</span>
                    <div className="item_pair_row padding_bottom">
                      <span className="green bold">{item?.number}</span>
                      <span className="bold captlize">
                        {item?.name || "unknown"}
                      </span>
                    </div>
                    <div className="item_pair_row padding_bottom">
                      <span className="gray captlize small">type</span>
                      <span className="captlize small yellow">
                        {item?.type?.type}
                      </span>
                    </div>
                    <div className="item_pair_row padding_bottom">
                      <span className="captlize small gray">purpose</span>
                      <span className="small captlize green">
                        {item?.purpose || "unknown"}
                      </span>
                    </div>
                    <div className="item_pair_row padding_bottom">
                      <span className="small gray bold">Rating</span>
                      <Rating
                        value={item?.type?.rating || 0}
                        readOnly
                        size="small"
                      />
                    </div>
                    <div className="item_pair_row padding_bottom">
                      <span className="gray bold small">Last dail</span>
                      <span className="blue small">
                        {item?.lastDail
                          ? formatDistanceStrict(
                              new Date(item?.lastDail),
                              new Date()
                            )
                          : "Not dailed yet"}
                      </span>
                    </div>
                    {item?.type?.type === "interested" &&
                      item?.meeting &&
                      new Date(item?.meeting).getDate() >=
                        new Date().getDate() && (
                        <div className="item_pair_row padding_bottom">
                          <span className="small gray bold">
                            Upcoming Meeting
                          </span>
                          <span className="yellow small bold">
                            {new Date(item?.meeting).toLocaleString("en-US", {
                              month: "long",
                              day: "2-digit",
                            })}
                          </span>
                        </div>
                      )}
                    <div className="item_pair_row padding_bottom border-bottom">
                      <span className="small bold gray">Source</span>
                      <span className="captlize small blue">
                        {item?.source}
                      </span>
                    </div>
                    <div>
                      <span className="title">User Info</span>
                      <div className="item_pair_row padding_bottom">
                        <span className="small gray small">Owned By</span>
                        <span className="small bold green captlize">
                          {item?.user?.name}
                        </span>
                      </div>
                      <div className="item_pair_row border-bottom">
                        <span className="small gray bold">Registered At</span>
                        <span className="small yellow blue">
                          {new Date(item?.user?.createdAt).toLocaleString()}
                        </span>
                      </div>
                    </div>
                    <div>
                      <span className="title">Call History</span>
                      <div>
                        {item?.callHis?.length > 0 ? (
                          item?.callHis.map((item, index) => {
                            return (
                              <div key={index}>
                                <div className="item_pair_row padding_bottom">
                                  <span className="gray small">Date</span>
                                  <span className="small yellow">
                                    {new Date(item?.date).toLocaleString()}
                                  </span>
                                </div>
                                <div className="item_pair_row padding_bottom">
                                  <span className="captlize gray small">
                                    Comment After
                                  </span>
                                  <span className="small blue bold">
                                    {getInterval(item?.callEnd, item?.date)}
                                  </span>
                                </div>
                                <div className="item_pair_row padding_bottom  ">
                                  <span className="gray">Dailed By</span>
                                  <span className="captlize blue small">
                                    {item?.dailBy || "name"}
                                  </span>
                                </div>
                                {item?.seeling_detail && (
                                  <div className="call_detail_message border_bottom padding_bottom">
                                    <span className="title">
                                      Seeling Detail
                                    </span>
                                    <p>{item?.seeling_detail}</p>
                                  </div>
                                )}
                                {item?.buying_detail && (
                                  <div className="call_detail_message border_bottom padding_bottom">
                                    <span className="title">Buying Detail</span>
                                    <p>{item?.buying_detail}</p>
                                  </div>
                                )}
                                {!item?.seeling_detail &&
                                  !item?.buying_detail && (
                                    <span
                                      style={{
                                        display: "inline-block",
                                        width: "100%",
                                        textAlign: "center",
                                        paddingBottom: "1rem",
                                      }}
                                      className="border_bottom red captlize"
                                    >
                                      Not Detail mentioned
                                    </span>
                                  )}
                              </div>
                            );
                          })
                        ) : (
                          <span className="captlize small ">
                            no history available
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="centered">no report available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallReport;
