import React from "react";
import "./ui.css";
const Button = ({ children, onClick, type, small, disabled }) => {
  return (
    <button
      disabled={disabled}
      style={{ width: small && "120px" }}
      onClick={onClick}
      type={type}
      className="ui_button"
    >
      {children}
    </button>
  );
};

export default Button;
