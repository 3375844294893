import React from "react";
import { Modal } from "react-bootstrap";
const CustomModal = ({ open, onClose, children, title, position }) => {
  return (
    <Modal centered={!position} show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
