import React from "react";
import "./ui.css";
const TextArea = ({ onChange, value, placeholder }) => {
  return (
    <textarea
      rows={3}
      className="ui_textarea"
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
  );
};

export default TextArea;
