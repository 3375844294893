import React, { useEffect, useState } from "react";
import "./auth.css";
import Input from "../ui/Input";
import Button from "../ui/Button";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import httpActions from "../../utils/httpAction";
import apis from "../../utils/apis";
import toast from "react-hot-toast";
import { firebaseActions } from "../../store/firebase-slice";
const Login = () => {
  const { Link, loading, setLoading, dispatch, navigate } =
    useProvideGeneralHooks();
  const [user, setUser] = useState({ email: "", password: "" });

  const userChnage = (value, type) => {
    setUser((prevUser) => ({ ...prevUser, [type]: value }));
  };

  const sendHandler = async (event) => {
    event.preventDefault();

    const data = {
      url:
        apis().loginUser +
        "?deviceToken=" +
        localStorage.getItem("deviceToken"),
      method: "POST",
      body: user,
    };
    setLoading(true);
    const result = await dispatch(httpActions(data));
    setLoading(false);
    if (result?.status) {
      localStorage.setItem("accessToken", result?.token?.accessToken);
      toast.success(result?.message);
      navigate("/");
    }
  };

  useEffect(() => {
    dispatch(firebaseActions.setToken({ statue: true }));
  }, []);

  return (
    <div className="auth_main">
      <form onSubmit={sendHandler}>
        <div className="auth_container">
          <div className="auth_header">
            <p>Welcome Back</p>
            <span>login to continue</span>
          </div>
          <div className="auth_item">
            <label>Email</label>
            <Input
              required
              type="email"
              placehlder="enter your email"
              onChange={(e) => userChnage(e.target.value, "email")}
              value={user.email}
            />
          </div>
          <div className="auth_item">
            <label>password</label>
            <Input
              required
              type="password"
              placehlder="enter 6-digit password"
              onChange={(e) => userChnage(e.target.value, "password")}
              value={user.password}
            />
          </div>
          <Button disabled={loading} type="submit">
            {loading ? "wait..." : "Login"}
          </Button>
          <div className="auth_options">
            <Link to="/register">create a new account?</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
