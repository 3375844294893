import { createSlice } from "@reduxjs/toolkit";

const firebaseSlice = createSlice({
  name: "firebase",
  initialState: {
    getToken: false,
  },
  reducers: {
    setToken(state, action) {
      state.getToken = action.payload.status;
    },
  },
});

export const firebaseActions = firebaseSlice.actions;
export default firebaseSlice;
