const apis = () => {
  const local = "http://localhost:8081/";
  const live = "https://marketing.hafizbrothersestate.com/";
  const list = {
    addContact: `${live}contact/add`,
    contactList: `${live}contact/list`,
    deleteContact: `${live}contact/delete`,
    updateContact: `${live}contact/update`,
    dispatchContact: `${live}contact/dispatch`,
    getSingleContact: `${live}contact/single`,
    addCallHistory: `${live}call/history/add`,
    addCallFeedback: `${live}call/feedback/add`,
    contactDetail: `${live}contact/detail`,
    loginUser: `${live}user/login`,
    registerUser: `${live}user/register`,
    verifyUserAccount: `${live}user/account/verify`,
    userVerify: `${live}user/verify`,
    getUserList: `${live}user/list`,
    logoutUser: `${live}user/logout`,
    shareContact: `${live}contact/share`,
    updateUser: `${live}user/update`,
    liveAddress: live,
  };

  return list;
};

export default apis;
