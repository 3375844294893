import React, { useState, useEffect } from "react";
const Recorder = ({ onStart, onStop }) => {
  const [audioFile, setAudioFile] = useState(null);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  useEffect(() => {
    // Request permission to access the microphone
    const initMediaRecorder = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          const url = URL.createObjectURL(event.data);
          setAudioFile(url); // Store the audio file
        }
      };
    };

    initMediaRecorder();
  }, []);

  useEffect(() => {
    if (mediaRecorder) {
      console.log("recording");
      mediaRecorder.start();
      setRecording(true);
      console.log("Recording started");
    }
  }, []);

  useEffect(() => {
    if (mediaRecorder && onStart === false) {
      mediaRecorder.stop();
      setRecording(false);
      console.log("Recording stopped");
      onStop(audioFile);
    }
  }, [onStart]);

  return (
    <div>
      {audioFile && (
        <div>
          <audio src={audioFile} controls />
        </div>
      )}
    </div>
  );
};

export default Recorder;
