import React, { useEffect, useState } from "react";
import CustomModal from "../common/CustomModal";
import Button from "../ui/Button";
import { Rating } from "@mui/material";
import Input from "../ui/Input";
import Recorder from "./Recorder";
import {
  todayDate,
  customerPurpose,
  customerType,
} from "../../utils/contactSource";
import TextArea from "../ui/TextArea";
import apis from "../../utils/apis";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import httpActions from "../../utils/httpAction";
import toast from "react-hot-toast";
const SendFeedack = ({
  openFeedback,
  feedBackCloseHandler,
  findedContact,
  contactId,
  onUpdateHistory,
  setOpenFeedback,
}) => {
  const [contactType, setContactType] = useState("");
  const [contactPurpose, setContactPurpose] = useState("");
  const { dispatch, loading, setLoading } = useProvideGeneralHooks();
  const [clientName, setClientName] = useState(null);
  const [feedback, setFeedback] = useState({
    purpose: "",
    type: "",
    seeling_detail: "",
    buying_detail: "",
    rating: "",
    meeting: "",
    other: "",
  });

  useEffect(() => {
    if (findedContact._id) {
      setContactType(findedContact?.type?.type);
      setContactPurpose(findedContact?.purpose);
      updateFeedback("purpose", findedContact?.purpose, setFeedback);
      updateFeedback("type", findedContact?.type?.type, setFeedback);
      updateFeedback("rating", findedContact?.type?.rating, setFeedback);
      updateFeedback("meeting", findedContact?.meeting, setFeedback);
     }
  }, [findedContact]);

  const conactActionHandler = (type, value) => {
    if (type === "a") {
      updateFeedback("purpose", value, setFeedback);
      setContactPurpose(value);
    } else {
      setContactType(value);
      updateFeedback("type", value, setFeedback);
    }
  };

  const feedbackChnage = (event, type) => {
    updateFeedback(type, event, setFeedback);
  };

  const sendFeedbackHandler = async () => {
    const data = {
      url: apis().addCallFeedback,
      method: "POST",
      body: { ...feedback, contactId, client: clientName },
      token: localStorage.getItem("accessToken"),
    };
    setLoading(true);
    const result = await dispatch(httpActions(data));
    setLoading(false);
    if (result?.status) {
      toast.success(result?.message);
      onUpdateHistory(result?.contact);
      setOpenFeedback(false);
    }
  };

  const clientNameChnage = (event) => {
    setClientName(event.target.value);
  };

  return (
    <CustomModal
      position="centered"
      open={openFeedback}
      onClose={feedBackCloseHandler}
    >
      <div>
        {!findedContact?.name && (
          <div className="contact_name_getter">
            <Input
              type="text"
              placehlder="enter client name"
              value={clientName}
              onChange={clientNameChnage}
            />
          </div>
        )}
        <div className="call_feedback_type">
          {customerType.map((item, index) => {
            return (
              <span
                className={contactType === item ? "filled" : ""}
                onClick={() => conactActionHandler("b", item)}
                key={index}
              >
                {item}
              </span>
            );
          })}
        </div>

        {contactType === "other" && (
          <div>
            <TextArea
              onChange={(e) => feedbackChnage(e.target.value, "other")}
              placeholder="type here..."
            />
          </div>
        )}

        {contactType === "interested" && (
          <div className="call_feedback_type">
            {customerPurpose.map((item, index) => {
              return (
                <span
                  className={contactPurpose === item ? "filled" : ""}
                  onClick={() => conactActionHandler("a", item)}
                  key={index}
                >
                  {item}
                </span>
              );
            })}
          </div>
        )}

        {contactType === "interested" && (
          <div className="item_pair_column">
            {contactPurpose === "seller" ? (
              <TextArea
                onChange={(e) =>
                  feedbackChnage(e.target.value, "seeling_detail")
                }
                placeholder="seeling detail"
              />
            ) : contactPurpose === "buyer" ? (
              <TextArea
                onChange={(e) =>
                  feedbackChnage(e.target.value, "buying_detail")
                }
                placeholder="buying detail"
              />
            ) : contactPurpose === "both" ? (
              <>
                <TextArea
                  onChange={(e) =>
                    feedbackChnage(e.target.value, "seeling_detail")
                  }
                  placeholder="seeling detail demand etc."
                />
                <TextArea
                  onChange={(e) =>
                    feedbackChnage(e.target.value, "buying_detail")
                  }
                  placeholder="buying detail demand etc."
                />
              </>
            ) : null}
          </div>
        )}
        {contactType === "interested" && (
          <div className="item_pair_column" style={{ padding: "0.6rem 0" }}>
            <div>
              <span className="small yellow">Schedule Meeting?</span>
              <input
                onChange={(e) => feedbackChnage(e.target.value, "meeting")}
                min={todayDate.toString()}
                type="date"
              />
            </div>
            <div className="item_pair_row ">
              <span className="small green">Rating </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Rating
                  value={feedback?.rating || 0}
                  onChange={(e) => feedbackChnage(e.target.value, "rating")}
                  size="small"
                />
              </div>
            </div>
          </div>
        )}

        <div style={{ padding: "1rem 0" }}>
          <Button disabled={loading} onClick={sendFeedbackHandler}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default SendFeedack;

function updateFeedback(type, value, setFeedback) {
  setFeedback((prevFeedback) => ({ ...prevFeedback, [type]: value }));
}
