import React, { useEffect, useState } from "react";
import "./callDetail.css";
import httpActions from "../../utils/httpAction";
import apis from "../../utils/apis";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import { Rating } from "@mui/material";
import { formatDistanceStrict } from "date-fns";
import { FaArrowLeft } from "react-icons/fa6";
import { getInterval } from "../../utils/contactSource";
const CallDetail = () => {
  const [findedContact, setFindedContact] = useState({});
  const [lastDail, setLastDail] = useState("");
  const [durationCount, setDurationCount] = useState("");
  const params = new URLSearchParams(window.location.search);
  const contactId = params.get("contactId");

  const { dispatch, navigate } = useProvideGeneralHooks();
  useEffect(() => {
    const getContact = async () => {
      const data = {
        url: apis().contactDetail + "?contactId=" + contactId,
      };

      const result = await dispatch(httpActions(data));
      if (result?.status) {
        setDurationCount(result?.contact?.lastDail);
        setFindedContact(result?.contact);
        const lastDail =
          result?.contact?.lastDail !== null
            ? formatDistanceStrict(
                new Date(result?.contact?.lastDail),
                new Date()
              )
            : "Not dailed yet";
        setLastDail(lastDail);
      }
    };
    getContact();
  }, [contactId]);

  const backHandler = () => {
    navigate("/");
  };

  useEffect(() => {
    return () => {
      localStorage.setItem("find", "yes");
    };
  }, []);
  return (
    <div className="call_detail_main">
      <div className="call_detail_header">
        <FaArrowLeft onClick={backHandler} className="rounded" />
      </div>
      <div className="call_detail_section">
        <div className="item_pair_row border_bottom padding_bottom">
          <span className="green">{findedContact?.number}</span>
          <span className="yellow captlize bold">
            {findedContact?.name || "unknown"}
          </span>
        </div>
        <div className="item_pair_row">
          <span className="captlize yellow bold">
            {findedContact?.type?.type}
          </span>
          <span>
            {
              <Rating
                readOnly
                value={findedContact?.type?.rating || 0}
                size="small"
              />
            }
          </span>
        </div>
        <div className="item_pair_row border_bottom padding_bottom">
          <span className="gray bold captlize">last Dail</span>
          <span
            style={{ color: !findedContact?.dailed && "red" }}
            className="bold green"
          >
            {lastDail}
          </span>
        </div>
        <div className="item_pair_row">
          <span className="gray">Purpose</span>
          <span className="captlize bold small">
            {findedContact?.purpose || "unknown"}
          </span>
        </div>
        {findedContact?.type?.type === "interested" &&
          findedContact?.meeting &&
          new Date(findedContact?.meeting).getDate() >=
            new Date().getDate() && (
            <div className="item_pair_row">
              <span className=" gray">Upcoming Meeting</span>
              <span className=" bold yellow">
                {new Date(findedContact?.meeting).toLocaleString("en-US", {
                  month: "long",
                  day: "2-digit",
                })}
              </span>
            </div>
          )}
        <div className="item_pair_row">
          <span className="gray bold">Owned By</span>
          <span className="green bold captlize">
            {findedContact?.user?.name}
          </span>
        </div>
        <div className="item_pair_row padding_bottom border_bottom ">
          <span className="bold gray ">Source</span>
          <span className="yellow bold captlize">{findedContact?.source}</span>
        </div>
        <span className="title">Call History</span>
        <div className="call_detail_history">
          {findedContact?.callHis?.length > 0 ? (
            findedContact?.callHis.map((item, index) => {
              return (
                <div className="call_history_each" key={index}>
                  <div className="item_pair_row padding_bottom">
                    <span className="gray small">Date</span>
                    <span className="small green bold">
                      {new Date(item?.date).toLocaleString()}
                    </span>
                  </div>
                  {/* <div className="padding_bottom audio">
                    {item?.audio && (
                      <audio
                        className="audio_player"
                        src={`${apis().liveAddress}${item?.audio}`}
                        controls
                      />
                    )}
                  </div> */}
                  <div className="item_pair_row padding_bottom">
                    <span className="captlize small gray">Comment After</span>
                    <span className="small">
                      {getInterval(item?.callEnd, item?.date)}
                    </span>
                  </div>
                  <div className="item_pair_row padding_bottom border_bottom ">
                    <span className="gray small">Dailed By</span>
                    <span className="captlize small blue">
                      {item?.dailBy || "name"}
                    </span>
                  </div>
                  {item?.seeling_detail && (
                    <div className="call_detail_message border_bottom padding_bottom">
                      <span className="title">Seeling Detail</span>
                      <p>{item?.seeling_detail}</p>
                    </div>
                  )}
                  {item?.buying_detail && (
                    <div className="call_detail_message border_bottom padding_bottom">
                      <span className="title">Buying Detail</span>
                      <p>{item?.buying_detail}</p>
                    </div>
                  )}
                  {item?.other && (
                    <div className="call_detail_message border_bottom padding_bottom">
                      <span className="title">other Detail</span>
                      <p>{item?.other}</p>
                    </div>
                  )}
                  {!item?.seeling_detail &&
                    !item?.buying_detail &&
                    !item?.other && (
                      <span
                        style={{
                          display: "inline-block",
                          width: "100%",
                          textAlign: "center",
                          padding: "1rem 0",
                        }}
                        className="border_bottom red captlize small"
                      >
                        Not Detail mentioned
                      </span>
                    )}
                </div>
              );
            })
          ) : (
            <span className="captlize small ">no history available</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallDetail;
