import React from "react";
import "./ui.css";
const Input = ({
  type,
  placehlder,
  onChange,
  required,
  value,
  max,
  id,
  min,
}) => {
  return (
    <input
      min={min}
      id={id}
      onInput={(event) => {
        if (type === "number" && event.target.value.length > max) {
          event.target.value = event.target.value.slice(0, max);
        }
      }}
      className="ui_input"
      onChange={onChange}
      required={required}
      value={value}
      placeholder={placehlder}
      type={type}
    />
  );
};

export default Input;
