import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const firebaseConfig = {
    apiKey: "AIzaSyBz_HB98i2I-HzJqUNIZ-CIpkDlpO3LvIw",
    authDomain: "call-tracking-3da27.firebaseapp.com",
    projectId: "call-tracking-3da27",
    storageBucket: "call-tracking-3da27.appspot.com",
    messagingSenderId: "583791905857",
    appId: "1:583791905857:web:ab62cac67cd2e7ee238ac0",
    measurementId: "G-9JKF8E6GSN"
  };

  export const app = initializeApp(firebaseConfig);
  export const messaging = getMessaging(app);