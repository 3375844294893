import React, { useEffect, useState } from "react";
import Select from "../ui/Select";
import Button from "../ui/Button";
import CustomModal from "../common/CustomModal";
import httpAction from "../../utils/httpAction";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import apis from "../../utils/apis";
const ContactDispatch = ({ open, setOpen, title, onShare }) => {
  const [userList, setUserList] = useState([]);
  const { dispatch } = useProvideGeneralHooks();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const getUsers = async () => {
      const data = {
        url: apis().getUserList,
        token: localStorage.getItem("accessToken"),
      };
      const result = await dispatch(httpAction(data));
      if (result?.status) {
        const transformedUsers = result?.list?.map((item, index) => {
          return { title: item.name, id: item._id };
        });
        setUserList(transformedUsers);
      }
    };

    getUsers();
  }, []);

  const closeHandler = () => {
    setOpen(false);
  };

  const onSelectUser = (user) => {
    const findedUserId = userList?.find((item) => item.title === user).id;
    setUserId(findedUserId);
  };

  return (
    <CustomModal title={`${title} contact`} open={open} onClose={closeHandler}>
      <div className="dispatch_contact_modal_main">
        <div>
          <Select
            onSelect={onSelectUser}
            placeholder="select user"
            options={userList}
          />
        </div>
        <div>
          <Button onClick={() => onShare(userId)} small>
            Send
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ContactDispatch;
