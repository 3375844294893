import React, { useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { MdUpdate } from "react-icons/md";
import { CiShare1 } from "react-icons/ci";
import ContactDispatch from "./ContactDispatch";
import { BsSend } from "react-icons/bs";
import httpActions from "../../utils/httpAction";
import apis from "../../utils/apis";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import "./savedContactList.css";
import toast from "react-hot-toast";
import CustomModal from "../common/CustomModal";

import Input from "../ui/Input";
import Select from "../ui/Select";
import { source } from "../../utils/contactSource";
import Button from "../ui/Button";
const SavedContactSingleRow = ({
  item,
  contacts,
  setContacts,
  index,
  setShared,
}) => {
  const [openUpdateContact, setOpenUpdateContact] = useState(false);
  const { dispatch } = useProvideGeneralHooks();
  const [selectedContact, setSelectedContact] = useState({
    name: item?.name,
    source: item?.source,
    number: item?.number,
  });

  const deleteContactHandler = async () => {
    const data = {
      url: apis().deleteContact + "?contactId=" + item?._id,
      method: "DELETE",
    };
    const result = await dispatch(httpActions(data));
    if (result?.status) {
      toast.success(result?.message);
      const prevContacts = [...contacts];
      prevContacts.splice(index, 1);
      setContacts(prevContacts);
    }
  };

  const openUpdateContactModal = () => {
    setOpenUpdateContact(true);
  };

  const closeUpdateModal = () => {
    setOpenUpdateContact(false);
  };

  const onSourceSelect = (value) => {
    setSelectedContact((prevContact) => ({ ...prevContact, source: value }));
  };

  const updateChnage = (event, type) => {
    setSelectedContact((prevContact) => ({ ...prevContact, [type]: event }));
  };

  const updateContactHandler = async (event) => {
    event.preventDefault();
    const data = {
      url: apis().updateContact + "?contactId=" + item?._id,
      method: "PUT",
      body: selectedContact,
    };
    const result = await dispatch(httpActions(data));
    if (result?.status) {
      toast.success(result?.message);
      const previousContacts = [...contacts];
      const index = previousContacts.findIndex(
        (contact) => contact.id === item.id
      );
      previousContacts.splice(index, 1, result?.findedContact);
      setContacts(previousContacts);
      setOpenUpdateContact(false);
    }
  };

  const chnageCheckbox = (event) => {
    const check = event.target.checked;
    if (check) {
      setShared((prevShared) => [...prevShared, item?._id]);
    } else {
      setShared((prevShared) => {
        return prevShared.filter((cont) => cont !== item?._id);
      });
    }
  };

  return (
    <tr>
      <CustomModal
        open={openUpdateContact}
        onClose={closeUpdateModal}
        title="update contact"
      >
        <form onSubmit={updateContactHandler}>
          <div className="item_pair_column">
            <div className="item_pair_row">
              <Input
                required
                onChange={(event) => updateChnage(event.target.value, "number")}
                value={selectedContact.number}
                type="number"
                max={11}
                placehlder="Number"
              />
              <Input
                required
                onChange={(event) => updateChnage(event.target.value, "name")}
                value={selectedContact.name}
                type="text"
                placehlder="Name"
              />
            </div>
            <Select
              required
              source={selectedContact.source}
              title="Select Source"
              onSelect={onSourceSelect}
              options={source}
            />
            <Button type="submit" small>
              Update
            </Button>
          </div>
        </form>
      </CustomModal>

      {!item?.isDispatched && (
        <td>
          <input onChange={chnageCheckbox} type="checkbox" />
        </td>
      )}
      <td className="gray">{item?._id?.substring(0, 6)}</td>
      <td className="captlize small">{item?.name}</td>
      <td>{item?.number}</td>
      <td name={item.source} className="captlize bold">
        {item?.source}
      </td>
      <td className="gray">
        {new Date(item?.createdAt).toLocaleString("en-US", {
          month: "long",
          day: "2-digit",
          year: "numeric",
        })}
      </td>
      <tr>
        <td>
          <MdUpdate
            onClick={openUpdateContactModal}
            className="rounded_icon yellow"
          />
        </td>
        {/* <td>
          <SiSimpleanalytics />
        </td> */}
        <td>
          <MdDeleteOutline
            onClick={deleteContactHandler}
            className="red rounded_icon"
          />
        </td>
      </tr>
    </tr>
  );
};

export default SavedContactSingleRow;
