import React, { useEffect, useState } from "react";
import UserSingleContact from "./UserSingleContact";
import apis from "../../utils/apis";
import httpActions from "../../utils/httpAction";
import { FiSearch } from "react-icons/fi";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import { IoPersonAdd } from "react-icons/io5";
import { FaUsersCog } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";

import {
  customerPurpose,
  customerType,
  getMinDate,
} from "../../utils/contactSource";
import SimpleBackdrop from "../common/SimpleBackdrop";
import { firebaseActions } from "../../store/firebase-slice";
import toast from "react-hot-toast";
import useScrollTop from "../../hooks/useScrollTop";
import { contactActions } from "../../store/contactSlide";
import { isEdge } from "rsuite/esm/internals/utils";
import { Modal } from "react-bootstrap";

const GetUserContacts = () => {
  const [role, setRole] = useState(null);
  const [tab, setTab] = useState({});
  const [isSerach, setIsSearch] = useState(false);
  const [project, setProject] = useState("select project");
  const [openProModel, setOpenProModel] = useState(false);

  const openProModelHandler = () => {
    setOpenProModel(true);
  };
  const closeProModelHandler = () => {
    setOpenProModel(false);
  };

  const selectProjectHandler = (item) => {
    setOpenProModel(false);
    setProject(item);
  };

  const {
    dispatch,
    loading,
    setLoading,
    user,
    navigate,
    filter,
    userContacts,
  } = useProvideGeneralHooks();

  const [selected, setSelected] = useState("all");
  const [contacts, setContacts] = useState([]);
  const [counts, setCounts] = useState(null);
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  // useScrollTop();
  const userRole = localStorage.getItem("userRole");

  useEffect(() => {
    setRole(userRole ? userRole?.toString() : user?.userRole);
  }, [userRole, user?.userRole]);

  const getContacts = async () => {
    const tabValue = localStorage.getItem("tab");

    const formatedTab = JSON.parse(tabValue);

    if (formatedTab === "follow-up") {
      setSelected("follow-up");
    }

    Object.keys(formatedTab).forEach((item) => {
      // setSelected(formatedTab[item]);

      if (item === "sharedTo") {
        setSelected("shared");
      }

      if (item === "meeting") {
        setSelected("meeting");
      }

      if (
        typeof formatedTab[item] === "boolean" &&
        formatedTab[item] === true
      ) {
        setSelected("dailed");
      }

      if (
        typeof formatedTab[item] === "boolean" &&
        formatedTab[item] === false
      ) {
        setSelected("Undialed");
      }

      if (formatedTab[item] === "interested") {
        setSelected(formatedTab[item]);
      }
      if (formatedTab[item] === "not interested") {
        setSelected(formatedTab[item]);
      }
      if (formatedTab[item] === "default") {
        setSelected(formatedTab[item]);
      }
      if (formatedTab[item] === "buyer") {
        setSelected(formatedTab[item]);
      }
      if (formatedTab[item] === "seller") {
        setSelected(formatedTab[item]);
      }

      if (formatedTab[item] === "other") {
        setSelected(formatedTab[item]);
      }
      if (formatedTab[item] === "not answering") {
        setSelected(formatedTab[item]);
      }
    });

    const data = {
      url: apis().contactList,
      method: "POST",
      body: { filter: isSerach ? tab : formatedTab },
      token: localStorage.getItem("accessToken"),
    };
    setLoading(true);
    const result = await dispatch(httpActions(data));
    setLoading(false);
    if (result?.status) {
      setContacts(result?.list);
      setCounts(result?.count);
      setIsSearch(false);
    }
  };

  useEffect(() => {
    getContacts();
  }, [tab]);

  const tabeHandler = (value) => {
    setSelected(value);

    if (value.includes("buyer") || value.includes("seller")) {
      setTab({ purpose: value });
      setLocalTab("purpose", value);
      // dispatch(contactActions.getContacts({ key: "purpose", value: value }));
    } else if (value.includes("terested") || value.includes("default")) {
      setTab({ "type.type": value });
      setLocalTab("type.type", value);
      // dispatch(contactActions.getContactByType({ value: value }));
    } else if (value.includes("all")) {
      // dispatch(contactActions.getAllContacts());
      setTab({});
      setLocalTab("single", {});
    } else if (value.includes("shared")) {
      setTab({ sharedTo: user?.userId });
      setLocalTab("sharedTo", user?.userId);
    } else if (value.includes("follow-up")) {
      setTab(value);

      setLocalTab("follow", value);
    } else if (value.includes("meeting")) {
      setTab({
        meeting: {
          $gte: new Date(new Date().setHours(0, 0, 0, 999)).getTime(),
          $lte:
            new Date(new Date().setHours(23, 59, 59, 999)).getTime() +
            24 * 60 * 60 * 1000,
        },
      });
      setLocalTab("meeting", {
        $gte: new Date(new Date().setHours(0, 0, 0, 999)).getTime(),
        $lte:
          new Date(new Date().setHours(23, 59, 59, 999)).getTime() +
          24 * 60 * 60 * 1000,
      });
    } else if (value.includes("other")) {
      // dispatch(contactActions.getOtherContact({ value: value }));
      setTab({ "type.type": value });
      setLocalTab("type.type", value);
    } else if (value.includes("not answering")) {
      // dispatch(contactActions.getNotAnswering({ value: value }));
      setTab({ "type.type": value });
      setLocalTab("type.type", value);
    } else if (value.includes("Undialed")) {
      setTab("dailed", false);
      setLocalTab("dailed", false);
    } else {
      // dispatch(contactActions.getDailedContacts());
      setTab({ dailed: true });
      setLocalTab("dailed", true);
    }
  };

  const dateChnage = (event, type) => {
    setIsSearch(true);
    setDate((prevDate) => ({ ...prevDate, [type]: event.target.value }));
  };

  useEffect(() => {
    if (date.startDate && date.endDate) {
      setTab({
        lastDail: {
          $gte: new Date(
            new Date(date.startDate).setHours(0, 0, 0, 999)
          ).getTime(), // Start of the day (00:00:00)
          $lte: new Date(
            new Date(date.endDate).setHours(23, 59, 59, 999)
          ).getTime(), // End of the day (23:59:59)
        },
      });
    }
  }, [date.startDate, date.endDate]);

  const searchChnage = (event) => {
    const value = event.target.value;
    setIsSearch(true);
    setTimeout(() => {
      setTab({ number: { $regex: value, $options: "i" } });

      if (!value) {
        setTab({});
      }
    }, 500);
  };
  const logoutHandler = async () => {
    const data = {
      url: apis().logoutUser + "?email=" + user?.email,
    };

    const result = await dispatch(httpActions(data));
    if (result?.status) {
      localStorage.clear();
      dispatch(firebaseActions.setToken({ status: false }));
      navigate("/login");
    }
  };

  const reportGenerateHandler = () => {
    if (!date.startDate || !date.endDate || contacts?.length === 0) {
      toast.error("please select date creteria");
      return;
    }

    localStorage.setItem("report", JSON.stringify(contacts));
    navigate("/call/report");
  };
  const itemId = localStorage.getItem("lastContact");
  const isFind = localStorage.getItem("find");
  useEffect(() => {
    if (contacts?.length > 0 && isFind === "yes") {
      const element = document.getElementById(itemId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        element.style.borderColor = "green";
        localStorage.setItem("find", "no");
      }
    }
  }, [contacts, itemId]);

  return (
    <div className="user_contacts_main">
      <SimpleBackdrop loading={loading} />
      <div className="user_contact_header">
        <div className="user_contact_user_info">
          <div className="item_pair_row">
            <span className="captlize bold yellow contact_user_name small ">
              {user?.name}
            </span>
            <span onClick={logoutHandler} className="logout_action samll">
              logout
            </span>
          </div>
        </div>
        <div className="filter_search_main">
          <input
            onChange={searchChnage}
            placeholder="search here"
            type="number"
          />
          <span>
            <FiSearch />
          </span>
        </div>
        <div className="contact_types">
          {[
            ...customerPurpose,
            ...customerType,
            "dailed",
            "all",
            "shared",
            "follow-up",
            "meeting",
            "Undialed",
          ]
            .filter((contact) => contact !== "both")
            .map((item, index) => {
              return (
                <span
                  onClick={() => tabeHandler(item)}
                  className={item === selected ? "filled" : "white"}
                  key={index}
                >
                  {item}
                </span>
              );
            })}
        </div>
        <div className="contact_filter">
          {selected === "dailed" && (
            <>
              <div className="item_pair_row">
                <div className="contact_filter_item">
                  <label>start date</label>
                  <input
                    value={date.startDate}
                    max={getMinDate(date.endDate)}
                    onChange={(e) => dateChnage(e, "startDate")}
                    type="date"
                    className="filter_date_picker"
                  />
                </div>
                <div className="contact_filter_item">
                  <label>end date</label>
                  <input
                    value={date.endDate}
                    min={getMinDate(date.startDate)}
                    onChange={(e) => dateChnage(e, "endDate")}
                    type="date"
                    className="filter_date_picker"
                  />
                </div>
              </div>
              <div className="dailed_sub_filter ">
                {/* <div className="dailed_sub_filter_items"></div> */}
                <div className="dailed_sub_filter_report">
                  <span onClick={reportGenerateHandler}>Generate Report</span>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="filter_contact_count item_pair_row">
          <span style={{ fontSize: "12px" }}>
            filtered contacts: {contacts?.length}
          </span>
          <span style={{ fontSize: "12px" }}>total contacts: {counts}</span>
        </div>
        <div className="contact_projects">
          <Modal show={openProModel} onHide={closeProModelHandler}>
            <Modal.Header closeButton>
              <Modal.Title>Select a project</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="projects">
                {["abc", "def", "ghi", "jkl"].map((item, index) => {
                  return (
                    <span
                      onClick={() => selectProjectHandler(item)}
                      key={index}
                    >
                      {item}
                    </span>
                  );
                })}
              </div>
            </Modal.Body>
          </Modal>
          <div onClick={openProModelHandler} className="project">
            <span>{project}</span>
            <IoIosArrowDown />
          </div>
        </div>
      </div>
      <div>
        {contacts?.length > 0 ? (
          contacts.map((item, index) => {
            return (
              <UserSingleContact
                tab={tab}
                contacts={contacts}
                setContacts={setContacts}
                index={index}
                item={item}
                key={index}
              />
            );
          })
        ) : (
          <p className="centered">no countact found</p>
        )}
      </div>
      <div className="float_actions">
        {role === "admin" && (
          <span
            onClick={() => navigate("/account/list")}
            className="float_add_contact accounts"
          >
            <FaUsersCog />
          </span>
        )}
        <span
          onClick={() => navigate("/contact/add")}
          className="float_add_contact"
        >
          <IoPersonAdd />
        </span>
      </div>
    </div>
  );
};

export default GetUserContacts;

function setLocalTab(key = null, value = {}) {
  if (key === "single") {
    localStorage.setItem("tab", JSON.stringify({}));
  } else if (key === "follow") {
    localStorage.setItem("tab", JSON.stringify("follow-up"));
  } else {
    localStorage.setItem("tab", JSON.stringify({ [key]: value }));
  }
}
