import React from "react";
import Input from "../ui/Input";
import Select from "../ui/Select";
import { IoMdAdd } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { source } from "../../utils/contactSource";
const AddContactSingleRow = ({ contact, setContact, index, item }) => {
  const addContactRow = () => {
    const isLastIndex = item.id === contact[contact.length - 1].id;
    if (!isLastIndex) {
      return;
    }

    const element = document.getElementById(item.id);
    if (element.value.length !== 11) {
      element.style.borderColor = "red";
      element.focus();
      return;
    }

    const selectElement = document.getElementById(item.id + "1");
    if (selectElement.firstChild.value === "") {
      selectElement.style.borderColor = "red";
      selectElement.click();
      return;
    }

    const copyContact = [...contact];
    copyContact.push({ id: uuidv4(), name: "", number: "", source: "" });
    setContact(copyContact);
  };

  const deleteContactRow = () => {
    if (contact?.length === 1) {
      return;
    }

    setContact((prevContact) => {
      return prevContact.filter((result, i) => result.id !== item.id);
    });
  };

  const handleChange = (field, value) => {
    const updatedContacts = [...contact];
    updatedContacts[index] = {
      ...updatedContacts[index],
      [field]: value, // Update the specific field (name, number, or source)
    };
    setContact(updatedContacts); // Update the state with the modified array

    if (field === "number" && value.length === 11) {
      const element = document.getElementById(item.id);
      element.style.borderColor = "#a0a0a0";
    }
  };

  return (
    <div className="add_contact_item">
      <Input
        id={item.id}
        // value={contact[index]?.number}
        onChange={(e) => handleChange("number", e.target.value)}
        max={11}
        type="number"
        placehlder="number"
        required
      />
      <Input
        id={uuidv4()}
        // value={contact[index]?.name}
        onChange={(e) => handleChange("name", e.target.value)}
        type="text"
        placehlder="name"
        required
      />
      <Select
        options={source}
        id={item.id + "1"}
        placeholder="select source"
        source={contact[index]?.source}
        onSelect={(value) => handleChange("source", value)}
      />

      <div className="add_contact_row_actions">
        <span onClick={addContactRow}>
          <IoMdAdd />
        </span>
        <span onClick={deleteContactRow}>
          <MdDeleteOutline />
        </span>
      </div>
    </div>
  );
};

export default AddContactSingleRow;
