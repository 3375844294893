import { createSlice } from "@reduxjs/toolkit";

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    contacts: [],
    supply: [],
  },
  reducers: {
    setContact(state, action) {
      state.contacts = action.payload.contacts;
      state.supply = action.payload.supply;
    },
    getContacts(state, action) {
      const key = action.payload.key;
      const value = action.payload.value;
      state.contacts = state.supply.filter((item) => item[key] === value);
    },

    getContactByType(state, action) {
      const value = action.payload.value;
      state.contacts = state.supply.filter((item) => item.type.type === value);
    },
    getAllContacts(state, action) {
      state.contacts = state.supply;
    },
    getOtherContact(state, action) {
      const value = action.payload.value;
      state.contacts = state.supply.filter((item) => item.type.type === value);
    },

    getNotAnswering(state, action) {
      const value = action.payload.value;
      state.contacts = state.supply.filter((item) => item.type.type === value);
    },

    getDailedContacts(state, action) {
      state.contacts = state.supply.filter((item) => item.dailed);
    },
  },
});

export const contactActions = contactSlice.actions;
export default contactSlice;
