import React from "react";
import logo from "../../assets/veritical logo svg.svg";

const Header = () => {
  return (
    <div className="header_main" >
     <div className="header_logo" >
     <img src={logo} alt="Logo" />
     </div>
    </div>
  );
};

export default Header;
