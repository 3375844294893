import React, { useEffect, useState } from "react";
import "./savedContactList.css";
import SavedContactSingleRow from "./SavedContactSingleRow";
import httpActions from "../../utils/httpAction";
import apis from "../../utils/apis";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import Table from "../common/Table";
import Button from "../ui/Button";
import ContactDispatch from "./ContactDispatch";
import toast from "react-hot-toast";
import { count } from "rsuite/esm/internals/utils/ReactChildren";

const SavedContactList = () => {
  const [contacts, setContacts] = useState([]);
  const { dispatch, user, loading, setLoading, navigate } =
    useProvideGeneralHooks();
  const [shared, setShared] = useState([]);
  const [filter, setFilter] = useState({ isDispatched: false });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const getContact = async () => {
      const data = {
        url: apis().contactList,
        method: "POST",
        body: { filter: filter },
        token: localStorage.getItem("accessToken"),
      };
      setLoading(true);
      const result = await dispatch(httpActions(data));
      setLoading(false);
      if (result?.status) {
        setContacts(result?.list);
      }
    };

    if (!loading) {
      getContact();
    }
  }, [filter]);

  const header = [
    ...(filter.isDispatched ? [] : [{ title: "select" }]), // Show "select" if isDispatched is false
    { title: "id" },
    { title: "name" },
    { title: "number" },
    { title: "source" },
    { title: "date" },
    { title: "actions" },
  ];

  const modelOpenHandler = () => {
    setOpen(true);
  };

  const showDispatched = async () => {
    setFilter({ isDispatched: !filter.isDispatched });
  };

  const shareContactHandler = async (userId) => {
    if (!userId) {
      toast.error("please select a user first");
      return;
    }
    const data = {
      url: apis().dispatchContact + "?userId=" + userId,
      method: "POST",
      body: { list: shared },
      token: localStorage.getItem("accessToken"),
    };

    const result = await dispatch(httpActions(data));
    if (result?.status) {
      toast.success(result?.message);
      setOpen(false);
      setContacts((prevContacts)=>{
        return prevContacts.filter((cont)=>cont._id)
      })
    }
  };

  return (
    <div>
      <ContactDispatch
        contacts={contacts}
        title="dispatch"
        open={open}
        setOpen={setOpen}
        onShare={shareContactHandler}
      />
      <div className="saved_contact_list_header box">
        <Button
          disabled={shared?.length === 0}
          onClick={modelOpenHandler}
          small
        >
          Dispatch
        </Button>

        <div className="saved_contact_avail">
          <span>
            {filter?.isDispatched ? "Dispatched" : "Available"} Contacts
          </span>
          <span>
            {
              contacts?.filter((item) =>
                filter.isDispatched ? item?.isDispatched : !item.isDispatched
              ).length
            }
          </span>
        </div>
        <div
          onClick={showDispatched}
          className={
            filter?.isDispatched
              ? "saved_contact_avail dispatched toggle_color"
              : "saved_contact_avail dispatched"
          }
        >
          <span>
            {filter.isDispatched ? "Back to available" : "Goto dispatched"}{" "}
            Contacts
          </span>
        </div>
        <div
          onClick={() => navigate("/contact/add")}
          className="saved_contact_avail dispatched add"
        >
          <span>Add Contacts</span>
        </div>
      </div>
      <div className="saved_contact_list">
        <Table header={header}>
          {loading ? (
            <span className="loading">please wait...</span>
          ) : (
            <>
              {contacts?.map((item, index) => (
                <SavedContactSingleRow
                  shared={shared}
                  setShared={setShared}
                  contacts={contacts}
                  setContacts={setContacts}
                  item={item}
                  key={item?._id}
                  index={index}
                />
              ))}
            </>
          )}
        </Table>
      </div>
    </div>
  );
};

export default SavedContactList;
