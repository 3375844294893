import React, { useEffect, useState } from "react";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import httpActions from "../../utils/httpAction";
import apis from "../../utils/apis";
import { Navigate, Outlet } from "react-router-dom";
import { userActions } from "../../store/user-slice";
const UserSuper = () => {
  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const { dispatch, user } = useProvideGeneralHooks();

  useEffect(() => {
    const getVerification = async () => {
      const data = {
        url: apis().userVerify,
        token: localStorage.getItem("accessToken"),
      };
      setLoading(true);
      const result = await dispatch(httpActions(data));
      setLoading(false);

      if (result?.status) {
        setIsAuth(true);
        dispatch(
          userActions.setState({
            ...user,
            name: result?.user?.name,
            email: result?.user?.email,
            userId: result?.user?.userId,
            userRole: result?.user?.userRole,
          })
        );

        // dispatch(
        //   contactActions.setContact({
        //     contacts: result?.list,
        //     supply: result?.list,
        //   })
        // );
      }
    };

    getVerification();
  }, []);

  if (loading) {
    return <p className="centered">wait....</p>;
  }
  if (!isAuth) {
    return <Navigate to="/login" />;
  } else {
    return <Outlet />;
  }
};

export default UserSuper;
