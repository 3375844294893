import React from "react";
import "./auth.css";
import Button from "../ui/Button";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import apis from "../../utils/apis";
import httpActions from "../../utils/httpAction";
import toast from "react-hot-toast";
const VerifyAccount = () => {
  const { dispatch, loading, setLoading, navigate } = useProvideGeneralHooks();

  const params = new URLSearchParams(window.location.search);
  const token = params.get("link");

  const verifyHandler = async () => {
    const data = {
      url: apis().verifyUserAccount + "?link=" + token,
    };
    setLoading(true);
    const result = await dispatch(httpActions(data));
    setLoading(false);
    if (result?.status) {
      toast.success(result?.message);
      navigate("/login");
    }
  };

  return (
    <div className="auth_main">
      <div className="auth_verification">
        <span>Verify Your Account</span>
        <p>
          Welcome! Click the button below to verify your email address and
          activate your account. This step is essential to ensure the security
          of your profile and access all features.
        </p>
      </div>
      <Button disabled={loading} onClick={verifyHandler}>
        {loading ? "wait..." : "Verify"}
      </Button>
    </div>
  );
};

export default VerifyAccount;
