import React, { useState } from "react";
import "./getUserContacts.css";
import { Rating } from "@mui/material";
import Select from "../ui/Select";
import { formatDistanceStrict } from "date-fns";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import { IoCallOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa6";
import { MdHistoryToggleOff } from "react-icons/md";
import { LuMessageCircle } from "react-icons/lu";
import { v4 as uuidv4 } from "uuid";
import httpActions from "../../utils/httpAction";
import Button from "../ui/Button";
import apis from "../../utils/apis";
import SendFeedack from "./SendFeedack";
import { CiShare1 } from "react-icons/ci";
import toast from "react-hot-toast";
import CustomModal from "../common/CustomModal";
const UserSingleContact = ({ item, index, contacts, setContacts, tab }) => {
  const { Link, dispatch, loading, setLoading, navigate } =
    useProvideGeneralHooks();
  const [openFeedback, setOpenFeedback] = useState(false);
  const [findedContact, setFindedContact] = useState({});
  const [openShare, setOpenShare] = useState(false);
  const [shareDate, setShareData] = useState({ user: "", contact: "" });
  const [users, setUsers] = useState([]);

  const feedbackOpenHandler = async () => {
    if (item?.lastDail === null || item?.dailCount <= item?.callHis?.length) {
      toast.error("please make a call first");
      return;
    }
    const data = {
      url: apis().getSingleContact + "?contactId=" + item?._id,
      token: localStorage.getItem("accessToken"),
    };

    setLoading(true);
    const result = await dispatch(httpActions(data));
    setLoading(false);
    if (result?.status) {
      setFindedContact(result?.contact);
      setOpenFeedback(true);
    }
  };
  const feedBackCloseHandler = () => {
    setOpenFeedback(false);
  };

  const onUpdateHistory = (contact) => {
    const prevContacts = [...contacts];
    prevContacts.splice(index, 1, contact);
    setContacts(prevContacts);
    document.getElementById(item?._id).style.borderColor = "transparent";
  };

  const lastDail =
    item?.lastDail !== null
      ? formatDistanceStrict(new Date(item?.lastDail), new Date())
      : "Not dailed yet";

  const callHistoryHandler = async (event) => {
    const findedContact = contacts.find(
      (cont) => cont.dailCount > cont.callHis.length
    );

    if (findedContact) {
      event.preventDefault();

      const findedElement = document.getElementById(findedContact?._id);
      findedElement.style.borderColor = "red";
      findedElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
      toast.error("update the feedback for this contact");
      return;
    }

    localStorage.setItem("lastContact", item?._id);
    localStorage.setItem("find", "yes");
    const data = {
      url: apis().addCallHistory + "?contactId=" + item?._id,
      method: "PUT",
      token: localStorage.getItem("accessToken"),
    };
    const result = await dispatch(httpActions(data));
    if (result?.status) {
      const prevContact = [...contacts];
      prevContact.splice(index, 1, result?.contact);
      setContacts(prevContact);
    }
  };

  const navigateToDetail = () => {
    localStorage.setItem("lastContact", item?._id);
    navigate("/call/detail?contactId=" + item?._id);
  };

  const openShareHandler = async (type) => {
    const data = {
      url: apis().getUserList,
      token: localStorage.getItem("accessToken"),
    };
    const result = await dispatch(httpActions(data));
    if (result?.status) {
      setOpenShare(type);
      const formatedUser = result?.list?.map((item) => ({
        title: item.name,
        id: item?._id,
      }));

      setUsers(formatedUser);
    }
  };

  const onSelectUser = (value) => {
    const findedUserId = users.find((item) => item.title === value);
    setShareData({ name: findedUserId?.id, contact: item?._id });
  };

  const shareHandler = async () => {
    if (!shareDate.name || !shareDate.contact) {
      toast.error("plase select a user");
      return;
    }

    const data = {
      url: apis().shareContact,
      method: "POST",
      body: shareDate,
    };
    const result = await dispatch(httpActions(data));
    if (result?.status) {
      setOpenShare(false);
      toast.success(result?.message);
    }
  };

  return (
    <div id={item?._id} className="user_call_single_main">
      <CustomModal
        position
        open={openShare}
        onClose={() => openShareHandler(false)}
      >
        <div className="item_pair_column">
          <Select
            placeholder="select a user"
            onSelect={onSelectUser}
            options={users}
          />
          <Button onClick={shareHandler}>Send</Button>
        </div>
      </CustomModal>
      <div className="user_call_header_icon">
        <span onClick={() => openShareHandler(true)}>
          <CiShare1 className="green" />
        </span>
      </div>
      <SendFeedack
        setOpenFeedback={setOpenFeedback}
        onUpdateHistory={onUpdateHistory}
        contactId={item?._id}
        openFeedback={openFeedback}
        feedBackCloseHandler={feedBackCloseHandler}
        findedContact={findedContact}
      />
      <div className="item_pair_row">
        <span className="small green">{item?.number}</span>
        <span className="captlize small">{item?.name || "unknown"}</span>
      </div>
      <div className="item_pair_row">
        <span name={item?.type?.type}>{item?.type?.type || "default"}</span>
        <span>
          <Rating
            size="small"
            value={item?.type?.rating || 0} // Use `value` instead of `defaultValue`
            readOnly
          />
        </span>
      </div>
      <div className="item_pair_row">
        <span className="small">Purpose</span>
        <span className="small blue">{item?.purpose || "unknown"}</span>
      </div>
      <div className="item_pair_row">
        <span className="small gray">Last Dail</span>
        <span style={{ color: !item?.dailed && "red" }} className="small bold">
          {lastDail}
        </span>
      </div>
      {item?.type?.type === "interested" &&
        item?.meeting &&
        new Date(item?.meeting).getDate() >= new Date().getDate() && (
          <div className="item_pair_row">
            <span className="small gray">Upcoming Meeting</span>
            <span className="small bold yellow">
              {new Date(item?.meeting).toLocaleString("en-US", {
                month: "long",
                day: "2-digit",
              })}
            </span>
          </div>
        )}
      <div className="item_pair_row">
        <Link
          onClick={callHistoryHandler}
          className="link_call"
          to={`tel:${item?.number}`}
        >
          <IoCallOutline />
        </Link>
        <Link
          target="_blank"
          className="link_whatsapp"
          to={`https://wa.me/+92${item.number}`}
        >
          <FaWhatsapp />
        </Link>
      </div>
      <div className="item_pair_row">
        <span onClick={navigateToDetail} className="call_history">
          <MdHistoryToggleOff />
        </span>
        <span onClick={feedbackOpenHandler} className="call_feedback">
          {loading ? (
            <span style={{ fontSize: "12px" }}>wait...</span>
          ) : (
            <LuMessageCircle />
          )}
        </span>
      </div>
    </div>
  );
};

export default UserSingleContact;
