import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    name: null,
    email: null,
    userId: null,
    userRole: null,
  },
  reducers: {
    setState(state, action) {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.userId = action.payload.userId;
      state.userRole = action.payload.userRole;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice;
