import React, { useState } from "react";
import AddContactSingleRow from "./AddContactSingleRow";
import Button from "../ui/Button";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import httpActions from "../../utils/httpAction";
import apis from "../../utils/apis";
import "./addContact.css";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
const AddContact = () => {
  const [contact, setContact] = useState([
    { id: "fdfdf", name: "", source: "", number: "" },
  ]);
  const { dispatch, loading, setLoading, navigate, user } =
    useProvideGeneralHooks();
  const savedHandler = async () => {
    const filteredContacts = contact.filter(
      (item) => item.number !== "" || item.source !== ""
    );

    if (filteredContacts.length === 0) {
      return;
    }

    const data = {
      url: apis().addContact,
      method: "POST",
      body: { numbers: filteredContacts },
      token: localStorage.getItem("accessToken"),
    };
    setLoading(true);
    const result = await dispatch(httpActions(data));
    setLoading(false);
    toast.success(result?.message);
    if (user?.userRole === "user") {
      navigate("/");
    } else {
      navigate("/contact/list");
    }
  };

  return (
    <div className="add_contact_main">
      <div className="add_contact_header box">
        <div className="add_contact_header_actions">
          <Button onClick={savedHandler} small>
            {loading ? "saving..." : "Save"}
          </Button>
          <Link to="/contact/list">
            <Button small>Contact List</Button>
          </Link>
        </div>
      </div>
      <div className="add_contact_table">
        {contact.map((item, index) => {
          return (
            <AddContactSingleRow
              key={item.id}
              contact={contact}
              setContact={setContact}
              index={index}
              item={item}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AddContact;
