import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

const useProvideGeneralHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const firebase = useSelector((state) => state.firebase.getToken);
  const userContacts = useSelector((state) => state.contacts);
  const filter = useSelector((state) => state.filter);
  return {
    navigate,
    dispatch,
    Link,
    loading,
    setLoading,
    user,
    firebase,
    filter,
    userContacts,
  };
};

export default useProvideGeneralHooks;
