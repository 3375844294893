import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./components/common/common.css";
import AddContact from "./components/contacts/AddContact";
import SavedContactList from "./components/contacts/SavedContactList";
import GetUserContacts from "./components/calls/GetUserContacts";
import CallDetail from "./components/calls/CallDetail";
import { messaging } from "./firebase";
import { getToken } from "firebase/messaging";
import Register from "./components/accounts/Register";
import Login from "./components/accounts/Login";
import VerifyAccount from "./components/accounts/VerifyAccount";
import UserSuper from "./components/auth/UserSuper";
import useProvideGeneralHooks from "./hooks/useProvideGeneralHooks";
import CallReport from "./components/calls/reports/CallReport";
import Recorder from "./components/calls/Recorder";
import AccountApprove from "./components/accounts/AccountApprove";
import AccountList from "./components/accounts/AccountList";
import Layout from "./components/layout/Layout";
const App = () => {
  const { firebase } = useProvideGeneralHooks();

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
          // Call getPermission after successful registration
          getPermission();
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, [firebase]);

  async function getPermission() {
    const registration = await navigator.serviceWorker.getRegistration();

    if (!registration) {
      console.error("No active Service Worker found.");
      return;
    }

    // Request notification permission
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      console.log("Permission granted. Getting token...");

      try {
        const token = await getToken(messaging, {
          vapidKey:
            "BNKwo38XdBH1s5UAWBxM9tlcNJbL5OrJ4_27oo6mEuNo39m8JsSl9vN3KNQ2soGk6_jYYXPUW-hCXbhyi5RyFUU",
        });

        if (token) {
          console.log("Token generated:", token);
          // Send the token to your server
          localStorage.setItem("deviceToken", token);
        } else {
          console.warn(
            "No registration token available. Request permission to generate one."
          );
        }
      } catch (error) {
        console.error("An error occurred while retrieving token:", error);
      }
    } else if (permission === "denied") {
      alert("Notification permission was denied.");
    }
  }

  return (
    <div className="wrapper">
      <Routes>
        <Route path="/account/verify" element={<VerifyAccount />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="account/approve" element={<AccountApprove />} />

        <Route element={<Layout />}>
          <Route element={<UserSuper />}>
            <Route path="/" element={<GetUserContacts />} />
            <Route path="/call/detail" element={<CallDetail />} />
            <Route path="/call/report" element={<CallReport />} />
            <Route path="/contact/add" element={<AddContact />} />
            <Route path="/contact/list" element={<SavedContactList />} />
            <Route path="/account/list" element={<AccountList />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default App;
