import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    filter: null,
  },
  reducers: {
    setFilter(state, action) {
      state.filter = action.payload.filter;
    },
  },
});

export const filterActions = filterSlice.actions;
export default filterSlice;
