import { intervalToDuration, formatDistanceStrict } from "date-fns";
export const source = [
  { title: "whatsapp" },
  { title: "facebook" },
  { title: "other" },
];

export const customerPurpose = ["buyer", "seller", "both"];
export const customerType = [
  "interested",
  "not interested",
  "default",
  "other",
  "not answering",
];

const formatedDate = new Date().toLocaleString("en-US", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});
const arrayDay = formatedDate.split("/");
export const todayDate = arrayDay[2] + "-" + arrayDay[0] + "-" + arrayDay[1];

export function getMinDate(date) {
  const formatedDate = new Date(date).toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const arrayDay = formatedDate.split("/");
  const todayDate = arrayDay[2] + "-" + arrayDay[0] + "-" + arrayDay[1];
  return todayDate;
}

export const getInterval = (start, end) => {
  // console.log(start, end);
  const duration = formatDistanceStrict(new Date(start), new Date(end));

  return duration;
};
