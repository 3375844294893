import React from "react";
import { CgMail } from "react-icons/cg";
import Button from "../ui/Button";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";

const AccountApprove = () => {
  const { Link } = useProvideGeneralHooks();
  return (
    <div className="auth_main">
      <div className="auth_header padding_bottom">
        <CgMail />
        <p className="padding_bottom">Thank you for registering!</p>
        <span>
          Please check your email for a verification link to
          <strong className="yellow small"> activate</strong> your account.
        </span>
        <span>
          Don't forget to check your{" "}
          <strong className="yellow small"> spam or junk folder</strong> if you
          don't see it in your inbox
        </span>
      </div>
      <Link
        to="https://mail.google.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>Go to Gmail</Button>
      </Link>
      <div className="padding_top">
        <Link to="/login">Go-to Login?</Link>
      </div>
    </div>
  );
};

export default AccountApprove;
