import React, { useState } from "react";
import "./auth.css";
import Input from "../ui/Input";
import Button from "../ui/Button";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import httpActions from "../../utils/httpAction";
import apis from "../../utils/apis";
import toast, { LoaderIcon } from "react-hot-toast";
const Register = () => {
  const { Link, loading, setLoading, dispatch, navigate } =
    useProvideGeneralHooks();
  const [user, setUser] = useState({ name: "", email: "", password: "" });

  const userChnage = (value, type) => {
    setUser((prevUser) => ({ ...prevUser, [type]: value }));
  };

  const sendHandler = async (event) => {
    event.preventDefault();

    const data = {
      url: apis().registerUser,
      method: "POST",
      body: user,
    };
    setLoading(true);
    const result = await dispatch(httpActions(data));
    setLoading(false);
    if (result?.status) {
      toast.success(result?.message);
      navigate("/account/approve");
    }
  };

  return (
    <div className="auth_main">
      <form onSubmit={sendHandler}>
        <div className="auth_container">
          <div className="auth_header">
            <p>Welcome</p>
            <span>Register a new account</span>
          </div>
          <div className="auth_item">
            <label>name</label>
            <Input
              required
              type="text"
              placehlder="enter your name"
              value={user.name}
              onChange={(e) => userChnage(e.target.value, "name")}
            />
          </div>
          <div className="auth_item">
            <label>Email</label>
            <Input
              required
              type="email"
              placehlder="enter your email"
              onChange={(e) => userChnage(e.target.value, "email")}
              value={user.email}
            />
          </div>
          <div className="auth_item">
            <label>password</label>
            <Input
              required
              type="password"
              placehlder="enter 6-digit password"
              onChange={(e) => userChnage(e.target.value, "password")}
              value={user.password}
            />
          </div>
          <Button disabled={loading} type="submit">
            {loading ? "wait..." : "Register"}
          </Button>
          <div className="auth_options">
            <Link to="/login">have already account ?Login</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Register;
