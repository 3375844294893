import React, { useEffect, useRef, useState } from "react";
import "./ui.css";
const Select = ({ onSelect, source, placeholder, id, options, required }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(source);
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const [data, setData] = useState(options);

  const openHandler = () => {
    setOpen(true);
  };

  const valueSelectHandler = (value) => {
    setValue(value);
    onSelect(value);
    setOpen(false);
  };

  useEffect(() => {
    if (value) {
      selectRef.current.style.borderColor = "#a0a0a0";
    }
  }, [value]);

  const valueChnage = (event) => {
    const value = event.target.value;
    setValue(event.target.value);
    if (value) {
      const findedItems = options.filter((item) => item.title.includes(value));
      setData(findedItems);
    } else {
      setData(options);
    }
  };

  const inputTouchHandler = (event) => {
    setOpen(true);
  };

  return (
    <div className="select_section">
      <div
        ref={selectRef}
        id={id}
        className="select_main"
        onClick={openHandler}
      >
        <input
          onFocus={inputTouchHandler}
          ref={inputRef}
          required={required}
          onChange={valueChnage}
          value={value}
          type="text"
          placeholder={placeholder}
        />
      </div>
      {open && (
        <div className="ui_inner_select">
          {data?.length > 0 ? (
            data.map((item, index) => {
              return (
                <span
                  onClick={() => valueSelectHandler(item.title)}
                  key={index}
                >
                  {item.title}
                </span>
              );
            })
          ) : (
            <span className="select_placeholder">no item found</span>
          )}
        </div>
      )}
    </div>
  );
};

export default Select;
