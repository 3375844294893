import { configureStore } from "@reduxjs/toolkit";
import firebaseSlice from "./firebase-slice";
import userSlice from "./user-slice";
import contactSlice from "./contactSlide";
import filterSlice from "./filter-slice";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    firebase: firebaseSlice.reducer,
    filter: filterSlice.reducer,
    contacts: contactSlice.reducer,
  },
});

export default store;
