import React, { useEffect, useState } from "react";
import httpActions from "../../utils/httpAction";
import apis from "../../utils/apis";
import Button from "../ui/Button";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import toast from "react-hot-toast";
const AccountList = () => {
  const [users, setUsers] = useState([]);
  const { dispatch, navigate } = useProvideGeneralHooks();

  useEffect(() => {
    const getUsers = async () => {
      const data = {
        url: apis().getUserList,
        token: localStorage.getItem("accessToken"),
      };

      const result = await dispatch(httpActions(data));
      if (result?.status) {
        setUsers(result?.list);
      }
    };
    getUsers();
  }, []);

  const updateUserHandler = async (id) => {
    const data = {
      url: apis().updateUser + "?userId=" + id,
      method: "POST",
      body: { status: true },
    };
    const result = await dispatch(httpActions(data));
    if (result?.status) {
      toast.success(result?.message);
      navigate("/");
    }
  };

  const userReportHandler = async (user) => {
    const data = {
      url: apis().loginUser,
      method: "POST",
      body: { email: user?.email, password: user?.pin },
    };
    const result = await dispatch(httpActions(data));
    if (result?.status) {
      localStorage.setItem("accessToken", result?.token?.accessToken);
      localStorage.setItem("userRole", "admin");
      navigate("/");
    }
  };

  return (
    <div className="user_account_list_main">
      <div className="users_list">
        {users?.map((user, index) => {
          return (
            <div key={index} className="user_list_main">
              <div className="item_pair_row padding_bottom border_bottom">
                <span className="captlize small bold green">{user?.name}</span>
                <span style={{ textAlign: "right" }} className="yellow small">
                  {new Date(user?.createdAt).toLocaleDateString()}
                </span>
              </div>
              <div className="padding_bottom item_pair_row ">
                <span className="gray blue padding_top">{user?.email}</span>
                <span
                  className="small captlize padding_top"
                  style={{
                    color: user?.verified ? "seagreen" : "red",
                    textAlign: "right",
                  }}
                >
                  {user?.verified ? "verified" : "un-verified"}
                </span>
              </div>
              <div className="item_pair_column">
                <Button
                  disabled={user?.verified}
                  onClick={() => updateUserHandler(user?._id)}
                >
                  {user?.verified ? "Verified" : "   Approve"}
                </Button>
                <span
                  onClick={() => userReportHandler(user)}
                  className="user_report_link"
                >
                  View Report
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AccountList;
